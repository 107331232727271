import React from "react";
import { Link } from "gatsby";
import {Button, Row, Col, Card, Collapse, Space} from 'antd';

import Layout from '../components/layout';
import {WEB_APP_URL, GET_TICKETTAMER} from '../constant';
// import {showMobileAppNoti} from '../helpers/utils';
import FooterBox from '../components/footerBox';

import ticketIcon from '../images/illustration-80-ticket.svg';
import lowCostIcon from '../images/illustration-80-low-cost.svg';
import stayUpIcon from '../images/illustration-80-stay-up-to-date.svg';
import payIcon from '../images/illustration-80-pay-your-fines.svg';
import convenientIcon from '../images/illustration-80-convenient.svg';
import reinstateIcon from '../images/illustration-80-reinstate.svg';
import upIcon from '../images/icon-ui-arrow-up.svg';
import downIcon from '../images/icon-ui-arrow-down.svg';
import checkIcon from '../images/circle-checked.svg';
import esFlagIcon from '../images/es-flag.png';
import banner from '../images/home-hero.svg';

import Routes from '../constant/routes';

const WHY = [
  {icon: ticketIcon, title: "Why Fix Your Ticket?", description: <div>
    Getting your <Link to={Routes.TRAFFIC_TICKET_LAWYERS}>traffic tickets</Link> dropped to non-moving violations keeps your record clean and insurance rates down. Reducing <Link to={Routes.FAKE_ID_LAWYERS}>Fake ID</Link>, <Link to={Routes.MIP_LAWYERS}>MIP</Link>, <Link to={Routes.POSSESSION_AND_PARAPHERNALIA_LAWYERS}>possession</Link>, and other non-traffic tickets to lesser offenses avoids problems with jobs and careers.
  </div>},
  {icon: lowCostIcon, title: "MIPs, Fake IDs", description: <div>
  Got busted for a <Link to={Routes.FAKE_ID_LAWYERS}>Fake ID</Link> or <Link to={Routes.POSSESSION_AND_PARAPHERNALIA_LAWYERS}>Minor in Possession</Link>? TicketTamer can fix your ticket by getting it reduced down to a lesser offense that will not cause problems for school or work for just $500. <Link to={Routes.PRICING}>Other charges</Link> may occasionally apply. Learn more about <Link to={Routes.FAKE_ID_LAWYERS}>Fake IDs</Link> and <Link to={Routes.MIP_LAWYERS}>MIPs</Link> here, or call <a href="tel:3147284444">(314) 728-4444</a> to hire us now.
  </div>},
  {icon: stayUpIcon, title: "Stay Up To Date", description: <div>
Get email and text updates when anything happens on your case. Get case update 24/7. Questions? <a href="tel:3147284444">(314) 728-4444</a> or <a href="mailto:info@tickettamer.com">info@tickettamer.com</a>.
  </div>},
  {icon: payIcon, title: "Pay Your Fines", description: <div>
TicketTamer can pay your fine for just a $25 handling fee. We will make sure it gets taken care of right and on time, and let you know when it is done.
  </div>},
  {icon: convenientIcon, title: <div>
<Link style={{color: 'black'}} to={Routes.POSSESSION_AND_PARAPHERNALIA_LAWYERS}>CannaTamer</Link>
  </div>, description: <div>
    Got busted with weed or gear? Let TicketTamer fix your ticket by getting it reduced to a lesser offense that won’t cause problems for work or school for just $500. <Link to={Routes.PRICING}>Other charges</Link> may occasionally apply. Learn more here, or call <a href="tel:3147284444">(314) 728-4444</a> to hire us now.
  </div>},
  {icon: reinstateIcon, title: "Reinstate Your License", description: <div>
TicketTamer can get your driver’s license reinstated if it has been <Link to={Routes.SUSPENDED_LICENSE_LAWYERS}>suspended or revoked</Link>. We can even get you temporary driving privileges if you are not eligible for reinstatement. Suspensions are $300. Revocations are $500. Temporary driving privileges are $500. Get back on the road now with TicketTamer.
  </div>},
];

const CLIENTS = [
  {message: 'I had Ticket Tamers help me with my first ticket. It was a super simple process, and was also a great price. They were great with communicating the entire process to me.', name: 'Olivia George', job: 'Worker in Missouri'},
  {message: 'What a great company. Top notch communication. I would recommend to anyone needing their service. A+++. ', name: 'Jeffrey Pierce', job: 'Worker in Missouri'},
  {message: 'Richard kept us informed every step of the way with our case. He not only knows the law and knows it well, but genuinely cares about his clients. I would recommend him to anyone needing an EXCELLENT attorney.', name: 'Jessie Hicks', job: 'Worker in Missouri'},
];

const FAQ = [
  {key: '1', title: 'What is TicketTamer?', message: `TicketTamer is a web-based system for fixing your traffic and misdemeanor tickets as easily as possible and at a reasonable price. It is offered by ReubenLaw LLC., which is located at P.O. Box 39023, St. Louis, Mo., 63109. Phone: (314) 728-4444. It is not a referral service.

  When you upload your ticket into TicketTamer, you are hiring ReubenLaw LLC to represent you for the ticket(s) you have sent us, and agree to our Terms of Service and Privacy Policy. This is a typical attorney-client relationship, and all communications between you and ReubenLaw LLC will be privileged and confidential.`},
  {key: '2', title: 'What Will TicketTamer Do for Me?', message: `We represent you before the court on all matters relating to your ticket(s). Our primary goal is to get your moving violation reduced to a non-moving violation and get you through your ticket as easily and as quickly as possible. Getting your moving violation reduced to a non-moving violation could save you hundreds of dollars a year in higher insurance costs, and keep points off your driving record.

  Once we have entered on your behalf, you don’t have to worry about appearing in court, or even the court date. We do that for you, including the worrying.:)`},
  {key: '3', title: 'Will I still have to pay a fine?', message: `Yes. In most cases, your TicketTamer lawyer will work out a deal with the prosecutor to reduce the traffic offense down to a non-moving violation. You will still be charged a fine for the ticket – and it will generally be more than if you just pay the ticket. Fines between $150-$300 are common. You will also be assessed court costs as well, and they tend to run between $25 and $100. However, you won’t get any points on your record, which could save you hundreds of dollars a year in higher insurance since your ticket will not be reported to the state or your insurance company for three to five years.`},
  {key: '4', title: 'How much do your legal services cost?', message: <div>
<Link to={Routes.PRICING}>Check out our great prices here</Link>. &nbsp;Our attorney fees do not include your fine and court costs.
  </div>},
];

const WhyItem = ({item}) => {
  return (
  <div className="Why__Item">
    <img className="Why__Item__Icon"
      src={item?.icon}
      alt={item?.title}
    /> 
    <div className="Why__Item_Info">
      <div className="H3BlackLeft mb8">{item?.title}</div>
      <div className="BodyBlackLeft Why__Item__Des">
        {item?.description}
      </div>
    </div>
  </div>
);
}

const ClientItem = ({item}) => {
  return (
    <div style={{height: '100%'}}>
      <Card hoverable style={{height: '100%'}} bodyStyle={{height: '100%', textAlign: 'left'}}>
        <div className="Section__Client__Item">
          <div className="HeadingBlackLeft">{`${item.message}`}</div>
          <div className="Section__Client__User">
            <br />
            <div className="ButtonActionBlackLeft">{item.name}</div>
            <div className="BodyGreyLeft">{item.job}</div>
          </div>
        </div>
      </Card>
    </div>
  )
}

const HomePage = () => {
  
  return (
    <Layout title="Home">
      <div className="Banner">
        <div className="Banner__Flag mb16">
          <img src={esFlagIcon} alt="es flag" />
          <span className="ButtonLargeCTABlue">Se Habla Español!</span>
        </div>
        <a href="https://www.bbb.org/us/mo/saint-louis/profile/traffic-law-attorney/tickettamer-0734-310610575/#sealclick" target="_blank" rel="nofollow noreferrer">
          <img src="https://seal-stlouis.bbb.org/seals/blue-seal-200-42-bbb-310610575.png" style={{border: 0}} alt="TicketTamer BBB Business Review" />
        </a>
        <div className="DisplayBlackCenter Banner__Title">Fix Your Ticket Now</div>
        <div className="SubHeadingBlackCenter Banner__Description">Submit your ticket and get your quote instantly. </div>
        <div className="Banner__Buttons">
          <a href={`${WEB_APP_URL}/submit-ticket`} style={{minWidth: 300}}><Button type="primary" block className="LargeButton">Get Instant Quote</Button></a>
          <a href="https://calendly.com/tickettamer/15min?month={YYYY-MM}" style={{minWidth: 300}} target="_blank" rel="noreferrer"><Button className="LargeButton" block>Schedule Free Consultation</Button></a>
        </div>
        
        <Space size={24} className="mt40 Banner__Items">
          <div className="Banner__Item ButtonLargeCTA"><img src={checkIcon} className="Banner__Item__Img" />97% success rate since 2014</div>
          <div className="Banner__Item ButtonLargeCTA"><img src={checkIcon} className="Banner__Item__Img" />40% referral/return client rate</div>
          <div className="Banner__Item ButtonLargeCTA"><img src={checkIcon} className="Banner__Item__Img" />Payment Plans Accepted</div>
          <div className="Banner__Item ButtonLargeCTA"><img src={checkIcon} className="Banner__Item__Img" />All Attorneys In-House</div>
        </Space>
        {/* <div className="Banner__AppText">We're also available on</div>
        <Space className="Banner__Apps">
          <img src={apple} alt="app store" width={120} style={{cursor: 'pointer'}} onClick={showMobileAppNoti} />
          <img src={google} alt="google play" width={120} style={{cursor: 'pointer'}} onClick={showMobileAppNoti} />
        </Space> */}
        <div className="Banner__Image">
          <img src={banner} alt="banner" />
        </div>
      </div>

      <section className="Section__Why">
        <div className="max-width-container">
          <div className="Display-2BlackCenter mb16">Why TicketTamer?</div>
          <Row justify="center" className="mb80">
            <Col md={16}><div className="LeadingBlackCenter">TicketTamer is the easiest way to keep your insurance rates down by fixing your ticket and keeping your record clean.  Our experienced attorneys provide high-quality personal service at a fair price. We can even pay your fine for you.</div></Col>
          </Row>
          <Row gutter={[40, 40]}>
            {/* {WHY.map((i, index) => (
              <Col md={12} key={`Why_${index}`}>
                <WhyItem index={index} item={i} />
              </Col>
            ))} */}
            <Col md={12}><WhyItem index={0} item={WHY[0]} /></Col>
            <Col md={12}><WhyItem index={1} item={WHY[1]} /></Col>
            <Col md={12}><WhyItem index={2} item={WHY[2]} /></Col>
            <Col md={12}><WhyItem index={3} item={WHY[3]} /></Col>
            <Col md={12}><WhyItem index={4} item={WHY[4]} /></Col>
            <Col md={12}><WhyItem index={5} item={WHY[5]} /></Col>
          </Row>
        </div>
      </section>
      <section className="Section__Client">
        <div className="Display-2BlackLeft mb40 Section__Title">What clients say about TicketTamer?</div>
        <Row className="max-width-container Section__Client__List" gutter={[{md: 40, xs: 20}]}>
          {/* {CLIENTS.map((c, index) => (
            <Col md={8}>
              <ClientItem index={index} item={c} />
            </Col>
          ))} */}
          <Col md={8} xs={22}><ClientItem index={0} item={CLIENTS[0]} /></Col>
          <Col md={8} xs={22}><ClientItem index={1} item={CLIENTS[1]} /></Col>
          <Col md={8} xs={22}><ClientItem index={2} item={CLIENTS[2]} /></Col>
        </Row>
      </section>
      <section className="Section__FAQ">
        <div className="max-width-container">
          <Row justify="center">
            <Col md={16}>
              <div className="Display-2BlackCenter mb40 Section__Title">Frequently Asked Questions</div>
              <div>
                <Collapse accordion ghost expandIconPosition="start" expandIcon={({isActive}) => <img src={isActive ? upIcon : downIcon} alt="arrow icon" style={{margin:0}}/>}>
                  {FAQ.map(item => (
                    <Collapse.Panel style={{borderBottom: 'solid 1px #d8d8d8'}} key={item.key} header={<div className="SubHeadingBlackLeft">{item.title}</div>}>
                      <div className="BodyBlackLeft" style={{whiteSpace: 'pre-line'}}>{item.message}</div>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
              <div style={{ marginTop: 24, textAlign: 'center'}}>
                <Link to={Routes.FAQ}><Button>See more questions</Button></Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <FooterBox />
    </Layout>
  );
}

export default HomePage
